var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"background-color":"#f6f6f6"}},[_c('v-container',{staticClass:"d-flex flex-wrap align-start pa-0 py-12"},[_c('div',{staticClass:"col-md-5 col-12"},[_c('p',{staticClass:"primary--text mb-6 text-center text-sm-right ",staticStyle:{"font-size":"14px"}},[_vm._v("اهداف ما")]),_c('div',{staticClass:"footerdesc mb-5",staticStyle:{"font-size":"12px"},domProps:{"innerHTML":_vm._s(_vm.data.html)}}),_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"alink ml-lg-2 ml-1 ml-sm-1"},[_c('router-link',{staticStyle:{"text-decoration":"none","cursor":"pointer"},attrs:{"to":"/terms-and-conditions","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('a',{staticClass:"primary--text  ml-lg-2 ml-1 ml-sm-1",staticStyle:{"font-size":"14px"},attrs:{"role":"link"},on:{"click":navigate}},[_vm._v("قوانین و مقررات")])]}}])})],1),_c('div',{staticClass:"alink ml-lg-2 ml-1 ml-sm-1"},[_c('router-link',{staticStyle:{"text-decoration":"none","cursor":"pointer"},attrs:{"to":"/questions","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('a',{staticClass:"primary--text ml-lg-2 ml-1 ml-sm-1",staticStyle:{"font-size":"14px"},attrs:{"role":"link"},on:{"click":navigate}},[_vm._v("سوالات متداول")])]}}])})],1),_c('div',{staticClass:"alink ml-lg-2 ml-1 ml-sm-1"},[_c('router-link',{staticStyle:{"text-decoration":"none","cursor":"pointer"},attrs:{"to":"/advertising","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('a',{staticClass:"primary--text ml-lg-2 ml-1 ml-sm-1",staticStyle:{"font-size":"14px"},attrs:{"role":"link"},on:{"click":navigate}},[_vm._v("تبلیغات")])]}}])})],1),_c('div',[_c('router-link',{staticStyle:{"text-decoration":"none","cursor":"pointer"},attrs:{"to":"/support","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('a',{staticClass:"primary--text ml-lg-2 ml-1 ml-sm-1",staticStyle:{"font-size":"14px"},attrs:{"role":"link"},on:{"click":navigate}},[_vm._v("تماس با ما")])]}}])})],1)])]),_c('div',{staticClass:"col-12 col-md-4 col-sm-6"},[_c('p',{staticClass:"primary--text mb-6 text-center text-sm-right",staticStyle:{"font-size":"14px"}},[_vm._v("مجوز های ما")]),_c('div',{staticClass:"d-flex justify-center justify-sm-start"},_vm._l((_vm.data.certificate),function(cert,i){return _c('div',{key:i + 'c',staticClass:"ml-4"},[_c('div',{domProps:{"innerHTML":_vm._s(cert.value)}})])}),0)]),_c('div',{staticClass:"col-12 col-md-3 col-sm-6 overflow-hidden"},[_c('router-link',{attrs:{"to":{name:'download'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var href = ref.href;
return [_c('p',{staticClass:"defaultcolor mb-6 text-center text-sm-right",staticStyle:{"font-size":"14px"}},[_c('a',{staticClass:"textlinkhover",attrs:{"href":href,"role":"link"},on:{"click":navigate}},[_vm._v("دانلود اپلیکیشن")])])]}}])}),_c('div',{staticClass:"d-flex flex-wrap flex-lg-nowrap justify-center justify-sm-start"},_vm._l((_vm.download),function(down,i){return _c('a',{key:i,attrs:{"href":down.url,"target":"_blank"}},[_c('img',{staticClass:"ml-1",attrs:{"src":_vm.$store.state.thumbnail + down.image,"width":"122","height":"35.8"}})])}),0)],1)])],1),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"copyrighttext"},[_c('p',{staticClass:"ma-0 text-center",staticStyle:{"font-size":"12px"}},[_vm._v("تمامی حقوق این سایت متعلق به \"شرکت ویرا داده فیدار\" می باشد.")])])}]

export { render, staticRenderFns }