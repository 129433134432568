import Vue from 'vue'
import VueRouter from 'vue-router'

const Main = resolve => {
  require.ensure(['../views/Main'], () => {
    resolve(require('../views/Main'));
  });
};

const Profile = resolve => {
  require.ensure(['../views/Profile'], () => {
    resolve(require('../views/Profile'));
  });
};

const Cats = resolve => {
  require.ensure(['../views/Cats'], () => {
    resolve(require('../views/Cats'));
  });
};

const Company = resolve => {
  require.ensure(['../views/Company'], () => {
    resolve(require('../views/Company'));
  });
};

const Ex = resolve => {
  require.ensure(['../views/Ex'], () => {
    resolve(require('../views/Ex'));
  });
};

const Login = resolve => {
  require.ensure(['../views/Login'], () => {
    resolve(require('../views/Login'));
  });
};

const Register = resolve => {
  require.ensure(['../views/Register'], () => {
    resolve(require('../views/Register'));
  });
};

const Ads = resolve => {
  require.ensure(['../views/Ads'], () => {
    resolve(require('../views/Ads'));
  });
};

const Support = resolve => {
  require.ensure(['../views/Support'], () => {
    resolve(require('../views/Support'));
  });
};

const AboutUs = resolve => {
  require.ensure(['../views/AboutUs'], () => {
    resolve(require('../views/AboutUs'));
  });
};

const News = resolve => {
  require.ensure(['../views/News'], () => {
    resolve(require('../views/News'));
  });
};

const SingleNews = resolve => {
  require.ensure(['../views/SingleNews'], () => {
    resolve(require('../views/SingleNews'));
  });
};

const Questions = resolve => {
  require.ensure(['../views/Questions'], () => {
    resolve(require('../views/Questions'));
  });
};

const TermsAndConditions = resolve => {
  require.ensure(['../views/TermsAndConditions'], () => {
    resolve(require('../views/TermsAndConditions'));
  });
};

const Advertising = resolve => {
  require.ensure(['../views/Advertising'], () => {
    resolve(require('../views/Advertising'));
  });
};

const Download = resolve => {
  require.ensure(['../views/Download'], () => {
    resolve(require('../views/Download'));
  });
};

const NotFound = resolve => {
  require.ensure(['../views/NotFound'], () => {
    resolve(require('../views/NotFound'));
  });
};

const Calendar = resolve => {
  require.ensure(['../views/Calendar'], () => {
    resolve(require('../views/Calendar'));
  });
};

const Factors = resolve => {
  require.ensure(['../views/Factors'], () => {
    resolve(require('../views/Factors'));
  });
};

const Messages = resolve => {
  require.ensure(['../views/Messages'], () => {
    resolve(require('../views/Messages'));
  });
};

const SingleAd = resolve => {
  require.ensure(['../views/SingleAd'], () => {
    resolve(require('../views/SingleAd'));
  });
};

const SingleService = resolve => {
  require.ensure(['../views/SingleService'], () => {
    resolve(require('../views/SingleService'));
  });
};

const Map = resolve => {
  require.ensure(['../views/Map'], () => {
    resolve(require('../views/Map'));
  });
};

Vue.use(VueRouter);

import store from '../store/index';

const routes = [
  {
    path:'/',
    component : Main,
    name:'main'
  },
  {
    path:'/profile',
    component : Profile,
    name:'profile',
    beforeEnter(to, from, next) {
      if (store.state.userId)
        next();
      else next('/register');
    }
  },
  {
    path:'/cats',
    component : Cats,
    name:'cats'
  },
  {
    path:'/company/:id/:title?',
    component : Company,
    name:'company'
  },
  {
    path:'/calendar',
    component : Calendar,
    name:'calendar'
  },
  {
    path:'/factors',
    component : Factors,
    name:'factors'
  },
  {
    path:'/map',
    component : Map,
    name:'map'
  },
  {
    path:'/messages',
    component : Messages,
    name:'messages'
  },
  {
    path:'/ex',
    component : Ex,
    name:'ex'
  },
  {
    path:'/login',
    component : Login,
    name:'login',
    beforeEnter(to, from, next) {
      if (!store.state.userId)
        next();
      else next('/profile');
    }
  },
  {
    path:'/register',
    component : Register,
    name:'register',
    beforeEnter(to, from, next) {
      if (!store.state.userId)
        next();
      else next('/profile');
    }
  },
  {
    path:'/ads',
    component : Ads,
    name:'ads'
  },
  {
    path:'/support',
    component : Support,
    name:'support'
  },
  {
    path:'/aboutus',
    component : AboutUs,
    name:'aboutus'
  },
  {
    path:'/news',
    component : News,
    name:'news'
  },
  {
    path:'/news/single/:id/:title?',
    component : SingleNews,
    name:'singlenews'
  },
  {
    path:'/ads/single/:id/:title?',
    component : SingleAd,
    name:'singlead'
  },
  {
    path:'/services/single/:id/:title?',
    component : SingleService,
    name:'singleservice'
  },
  {
    path:'/download',
    component : Download,
    name:'download'
  },
  {
    path:'/questions',
    component : Questions,
    name:'questions'
  },
  {
    path:'/terms-and-conditions',
    component : TermsAndConditions,
    name:'terms'
  },
  {
    path:'/advertising',
    component : Advertising,
    name:'advertising'
  }
  , {
    path: '/404',
    name: '404',
    component: NotFound
  },
  {path: '*', redirect: '/404'},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {selector: to.hash};
    }
    return {x: 0, y: 0};
  },
})

export default router
