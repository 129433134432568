<template>
  <div>

    <WarningPaid :warning="warning"></WarningPaid>

    <v-app-bar
    height="82"
    color="white"
    class="d-none d-md-block"
    >
    <v-container class="pa-0">

      <div  class="mr-1 header align-center justify-space-between d-none d-md-flex">

        <template v-if="headloaded">
          <v-btn text color="primary"   v-for="menu in menus" :key="menu.id" :to="'/'+(menu.url==='#'?'':menu.url)" style="letter-spacing: 0px;padding: 3px!important;min-width:50px!important" >
             {{menu.title}}
          </v-btn>
        </template>
        <template v-else>
          <div v-for="i in 8" :key="i" style="letter-spacing: 0px" >
            <v-skeleton-loader type="button"></v-skeleton-loader>
          </div>
        </template>




      <div>



        <v-menu v-if="$store.state.userId" offset-y
                transition="slide-y-transition"
                class="profilemenu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                min-width="138"
                class="mr-lg-2 mr-md-2 btn"
            >
              <span class="text" >{{$store.state.userTitle}}</span>
            </v-btn>
          </template>
          <v-list>
            <router-link to="/profile" style="text-decoration: none;cursor: pointer" custom v-slot="{ navigate,href }">
            <v-list-item @click="navigate" :href="href" role="link">
              <v-list-item-title class="primary--text" style="font-size: 14px"><v-icon class="primary--text">mdi-account</v-icon> پروفایل</v-list-item-title>
            </v-list-item>
            </router-link>
            <router-link to="/messages" style="text-decoration: none;cursor: pointer" custom v-slot="{ navigate,href }">
              <v-list-item @click="navigate" :href="href" role="link">
                <v-list-item-title @click="notification='0'" class="primary--text" style="font-size: 14px"><v-icon class="primary--text">mdi-android-messages</v-icon>پیام های من<span v-if="parseInt(notification)>0"> ({{notification}}) </span></v-list-item-title>
              </v-list-item>
            </router-link>
            <router-link to="/factors" style="text-decoration: none;cursor: pointer" custom v-slot="{ navigate,href }">
              <v-list-item @click="navigate" :href="href" role="link">
                <v-list-item-title class="primary--text" style="font-size: 14px"><v-icon class="primary--text">mdi-cash</v-icon> فاکتور های من</v-list-item-title>
              </v-list-item>
            </router-link>
            <v-list-item>
              <v-list-item-title @click="logout" style="cursor: pointer;font-size: 14px;" class="primary--text"><v-icon class="primary--text">mdi-logout</v-icon>خروج </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>


        <router-link v-else to="/login" style="text-decoration: none;cursor: pointer" custom v-slot="{ navigate }">
          <v-btn
              height="42"
              class="mr-lg-10 mr-md-3 btn"
              @click="navigate"
              role="link"
          >
            ورود / ثبت نام
          </v-btn>
        </router-link>

        <router-link :to="{name : 'ads',query:{employ:1}}" style="text-decoration: none;cursor: pointer" custom v-slot="{ navigate , href }">
        <v-btn
            height="36"
            style="border-radius: 8px !important;
  font-size: 12px !important;
  letter-spacing: 0.5px !important;"
            class="mr-lg-2 mr-md-2"
            outlined
            :href="href"
            @click="navigate"
            role="link"
            color="primary"
        >
          آگهی استخدام
        </v-btn>
        </router-link>

      </div>

      <div class="logo">
        <img alt="لوگوی ایران آی سیب" title="ایران آی سیب"  src="../assets/image/Header/logo.png" class="headerimg" width="80%"/>
      </div>

      </div>


    </v-container>
    </v-app-bar>
    <v-app-bar
        class="d-md-none"
        color="white"
    >
      <v-container class="pa-0 d-flex justify-space-between align-center">

        <div>
          <v-app-bar-nav-icon @click="drawer = true" ></v-app-bar-nav-icon>
        </div>

        <div class="logo">
          <img alt="لوگوی ایران آی سیب" title="ایران آی سیب" src="../assets/image/Header/logo.png" class="headerimg"/>
        </div>

      </v-container>
    </v-app-bar>


    <v-navigation-drawer
        v-model="drawer"
        temporary
        right
        fixed
        style="z-index: 99999"
    >
      <v-list
          nav
          dense
      >
        <v-list-item-group
            v-model="group"
            class="primary--text"
        >
          <router-link v-for="(menu,i) in menus" :key="i" :to="'/'+menu.url" style="text-decoration: none;cursor: pointer" custom v-slot="{ navigate }">
            <v-list-item @click="navigate" role="link">
              <v-list-item-title class="defaultcolor" style="font-size: 14px !important;" >{{menu.title}}</v-list-item-title>
            </v-list-item>
          </router-link>
          <div v-if="$store.state.userId" >
            <router-link to="/profile" style="text-decoration: none;cursor: pointer" custom v-slot="{ navigate }">
              <v-list-item @click="navigate" role="link">
                <v-list-item-title class="primary--text" style="font-size: 14px !important;" ><span class="text" >{{$store.state.userTitle}}</span></v-list-item-title>
              </v-list-item>
            </router-link>
            <router-link to="/messages" style="text-decoration: none;cursor: pointer" custom v-slot="{ navigate }">
              <v-list-item @click="navigate" role="link">
                <v-list-item-title @click="notification='0'" class="primary--text" style="font-size: 14px !important;" ><span class="text" >پیام های من <span v-if="parseInt(notification)>0"> ({{notification}}) </span></span></v-list-item-title>
              </v-list-item>
            </router-link>
            <router-link to="/factors" style="text-decoration: none;cursor: pointer" custom v-slot="{ navigate }">
              <v-list-item @click="navigate" role="link">
                <v-list-item-title class="primary--text" style="font-size: 14px !important;" ><span class="text" >فاکتور های من</span></v-list-item-title>
              </v-list-item>
            </router-link>
              <v-list-item @click="logout" >
                <v-list-item-title class="primary--text" style="font-size: 14px !important;" >خروج</v-list-item-title>
              </v-list-item>
          </div>
          <router-link v-else to="/login" style="text-decoration: none;cursor: pointer" custom v-slot="{ navigate }">
            <v-list-item @click="navigate" role="link">
              <v-list-item-title class="primary--text" style="font-size: 14px !important;" >ورود / ثبت نام</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link :to="{name : 'ads',query:{employ:1}}" style="text-decoration: none;cursor: pointer" custom v-slot="{ navigate }">
            <v-list-item @click="navigate" role="link" style="border: 2px solid #570654">
              <v-list-item-title class="primary--text" style="font-size: 14px !important;" >آگهی استخدام</v-list-item-title>
            </v-list-item>
          </router-link>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

  </div>
</template>

<script>
import WarningPaid from "./WarningPaid";
import axios from "axios";
import moment from "jalali-moment";
import {mapGetters} from 'vuex';

export default {
  name: 'App',

  data() {
    return {
      drawer: false,
      group: null,
      toplineData : null,
      warning : false,
      menus : null,
      headloaded : false,
      notification : '0',
    }
  },
  watch : {
    uChange(){
      this.fetchProfile();
    },
  },
  methods : {
    logout(){
      localStorage.clear();
      this.$store.state.userId='';
      this.$store.state.userTitle = '';
      this.$store.state.notify.is = true;
      this.$store.state.notify.text = 'خارج شدید!';
      this.$router.push('/');
    },
    fetchMenu(){
      axios.get(this.$store.state.api + 'menu').then(response => {
        this.menus = response.data.data;
        this.headloaded = true;
      })
    },
    fetchProfile(){
      if(this.$store.state.userId){
        axios.get(this.$store.state.api + 'topline', {
          params : {
            userId : this.$store.state.userId,
          }
        }).then(response => {
          this.toplineData = response.data.data;
          this.notification = this.toplineData.notification;
          if(this.toplineData.title != this.$store.state.userTitle){
            localStorage.setItem('title', (this.toplineData.title));
            this.$store.state.userTitle = this.toplineData.title;
          }
          if (this.momentRel(this.toplineData.premiumTime)<0){
            this.warning = true;
          } else {
            this.warning = false;
          }
        })
      } else {
        this.warning = false;
      }
    },
    momentRel(date){
      var a = moment(new Date());
      var b = moment.unix(date);
      return b.diff(a, 'hours') + 1;
    },
  },
  computed : {
    ...mapGetters(['uChange'])
  },
  created() {
    this.fetchMenu();
    this.fetchProfile();
  },
  components : {
    WarningPaid,
  }
};
</script>


<style lang="scss">



.profilemenu .v-menu__content, .menuable__content__active {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 8px 10px 1px rgb(0 0 0 / 10%), 0px 3px 14px 2px rgb(0 0 0 / 10%) !important;
  border-radius: 4px 4px 6px 6px !important;
}

.header a:hover{
  color: #641a61 !important;
  user-select: none;
}
.logo {
  height: 42px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}



@media only screen and (max-width:1268px)  {

  .logo{
    height: 30.2px;
    width: 90px;
  }

  .headerimg{
    height: 55px;
    width: 50px;
  }
}

.text {
  display: block;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

</style>
