import Vue from 'vue'
import Vuex from 'vuex'


let host='https://server.iranicib.com/';

let Id=localStorage.getItem('token')?((localStorage.getItem('token')/1234)-1100):null;
let title=localStorage.getItem('title');

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    url:'https://iranicib.com',
    api:host+'api/',
    userId:Id,
    userTitle:title,
    mapApi: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjM0NDdmYTI3NjExMGFkNTdjODZiY2JiYWY0MTE2MDRlNTk3ZWE2YTU0Nzc1MTI5NWJiZDBiZmVhN2UxMTUxNWJhM2RmOTFjNTE2ZjQ4NzU2In0.eyJhdWQiOiI4NzMxIiwianRpIjoiMzQ0N2ZhMjc2MTEwYWQ1N2M4NmJjYmJhZjQxMTYwNGU1OTdlYTZhNTQ3NzUxMjk1YmJkMGJmZWE3ZTExNTE1YmEzZGY5MWM1MTZmNDg3NTYiLCJpYXQiOjE1ODY5ODE3NDcsIm5iZiI6MTU4Njk4MTc0NywiZXhwIjoxNTg5NTczNzQ3LCJzdWIiOiIiLCJzY29wZXMiOlsiYmFzaWMiXX0.dWM8XQGRGkawFME9K5wwBknVXFCvvPJxpKJ1sF4SxQxGwBmvqhdUoeh3aB3zcjRyxJYAACb7vz-XfIKKUWTnmzJiR8k60XZq_aa1UDuE-0yQ5AqZ3mTuCMbQI3pfYx7RCeYA6wa0vQBcMbxvsXNblnP4tw6BTKz7WaezMzbUbURi-9T3ZwezWy1Y2z8AkHo6Cgwkf9k5sxSh-erkSOD30zb2pJuJgDdH-QLnp1042YDrcYesuaJ3CC0dGOQpapqS6JW8Q-ijISSWxjvmt7UgZ1fEDakvJN3OOLb41v7mVstp71kiBtUCXI6Z3S60Wc_i6oRdoL3OoVNb2ajyijdGpA",
    notify:{
      is:false,
      text:'',
      type:'',
    },

    slider:null,
    upload:host+'upload/',
    thumbnail:host+'upload/thumbnail/',
    smallthumbnail:host+'upload/smallthumbnail/',
    loading : false,
    cityLat : 35.759176,
    cityLong : 51.405373,


  },
  getters : {
    uChange : state => state.userId
  }
})
