<template>
  <div>
  <div style="background-color: #f6f6f6">
    <v-container class="d-flex flex-wrap align-start pa-0 py-12">
      <div class="col-md-5 col-12">
        <p class="primary--text mb-6 text-center text-sm-right " style="font-size: 14px">اهداف ما</p>
        <div class="footerdesc mb-5" style="font-size: 12px" v-html="data.html"></div>
        <div class="d-flex flex-wrap">
          <div class="alink ml-lg-2 ml-1 ml-sm-1">
            <router-link to="/terms-and-conditions" style="text-decoration: none;cursor: pointer" custom v-slot="{ navigate }">
            <a @click="navigate" role="link" class="primary--text  ml-lg-2 ml-1 ml-sm-1" style="font-size: 14px">قوانین و مقررات</a>
            </router-link>
          </div>
          <div class="alink ml-lg-2 ml-1 ml-sm-1">
            <router-link to="/questions" style="text-decoration: none;cursor: pointer" custom v-slot="{ navigate }">
            <a @click="navigate" role="link" class="primary--text ml-lg-2 ml-1 ml-sm-1" style="font-size: 14px">سوالات متداول</a>
            </router-link>
          </div>
          <div class="alink ml-lg-2 ml-1 ml-sm-1">
            <router-link to="/advertising" style="text-decoration: none;cursor: pointer" custom v-slot="{ navigate }">
            <a @click="navigate" role="link" class="primary--text ml-lg-2 ml-1 ml-sm-1" style="font-size: 14px">تبلیغات</a>
            </router-link>
          </div>
          <div>
            <router-link to="/support" style="text-decoration: none;cursor: pointer" custom v-slot="{ navigate }">
          <a @click="navigate" role="link" class="primary--text ml-lg-2 ml-1 ml-sm-1" style="font-size: 14px">تماس با ما</a>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 col-sm-6">
        <p class="primary--text mb-6 text-center text-sm-right" style="font-size: 14px">مجوز های ما</p>
        <div class="d-flex justify-center justify-sm-start">
          <div v-for="(cert,i) in data.certificate" :key="i + 'c'" class="ml-4">
            <div v-html="cert.value" ></div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3 col-sm-6 overflow-hidden">
        <router-link :to="{name:'download'}" custom v-slot="{navigate,href}">
        <p class="defaultcolor mb-6 text-center text-sm-right" style="font-size: 14px"><a class="textlinkhover" @click="navigate" :href="href" role="link">دانلود اپلیکیشن</a></p>

        </router-link>
        <div class="d-flex flex-wrap flex-lg-nowrap justify-center justify-sm-start">
          <a v-for="(down,i) in download" :key="i" :href="down.url" target="_blank">
          <img :src="$store.state.thumbnail + down.image" width="122" height="35.8" class="ml-1"  />
          </a>
<!--          <a v-if="download" :href="download.sibappLink" target="_blank">
          <img src="../assets/image/Footer/sibapp.png" width="136" height="40" class="ml-lg-0 ml-1" />
          </a>-->
        </div>
      </div>
    </v-container>

  </div>
    <div class="copyrighttext">
        <p class="ma-0 text-center" style="font-size: 12px">تمامی حقوق این سایت متعلق به "شرکت ویرا داده فیدار" می باشد.</p>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'App',

  data() {
    return {
      download : null,
      data : {
        html : null
      },
    }
  },
  created() {
    axios.get(this.$store.state.api + 'downloadlink').then(response=>{
      this.download = response.data.data;
    });
    axios.get(this.$store.state.api + 'footer').then(response=>{
      this.data = response.data.data;
    });
  }
};
</script>

<style>
.footerdesc {
  color: #272727;
  text-align: justify;
  text-align-last: right;
}

@media only screen and (min-width: 960px) {
  .footerdesc {
    max-width: 490px;
  }
}

.alink::after {
  content: "|";
}

.copyrighttext{
  background-color: #570654;
  min-height: 70px;
  line-height: 21.8px;
  color: white;
  padding: 24px 0 15px 0;
}
</style>
