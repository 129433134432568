<template>
  <div v-if="warning" class="warningpaid d-flex align-center">
    <v-container class="pa-0 d-flex align-center">
      <svg class="my-1 ml-1" style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="#570654" d="M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
      </svg>
      <router-link :to="{name : 'profile'}" custom v-slot="{navigate,href}">
      <p class="my-1" style="font-size: 14px"> تعرفه شما پرداخت نشده و یا منقضی شده است . جهت پرداخت تعرفه به  <v-btn x-small @click="navigate" :href="href" role="link" class="btn" >پروفایل</v-btn> مراجعه کنید.</p>
      </router-link>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'App',
  props : {
    warning : {
      default : false,
    },
  },
  data() {
    return {

    }
  },
};
</script>

<style lang="scss">
.warningpaid{
  background-color: #f8eff1;
  min-height: 40px;
  color: #404040;
  user-select: none;
}

.paidlink:hover{
  color: #570654;
  cursor: pointer;
}
</style>
