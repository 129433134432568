
<template>
  <v-app>
    <Header></Header>
    <v-main>
      <router-view>
      </router-view>
      <Notify></Notify>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Notify from "@/components/Notify";
export default {
  name: 'App',

  data: () => ({
    //
  }),
  components : {
    Header,
    Footer,
    Notify,
  },
  metaInfo() {
    return {
      titleTemplate: function (chunk) {
        if(this.$route.name){
          if(this.$route.name !== 'main'){
            return `${chunk}
            |
          ایران آی سیب
          `
          }else {
            return `
              ایران آی سیب
        |
        بانک اطلاعات شرکت ها و صنایع ایران

              `
          }
        }
      },
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'تیم راه اندازی پروژه ایران آی سیب جهت دسترسی فعالین اقتصادی و صنایع کشور اقدام به درج اطلاعات و معرفی شرکت و صنایع ایران نموده است. '

      }],
    };
  }

};
</script>

<style lang="scss" >
@import "~animate.css";
@import "assets/css/font.css";
@import "assets/css/Normalize.scss";
@import "assets/css/custom.scss";

.v-application{
  font-family: "IranYekan" !important;
}

.v-application a {
  text-decoration: none;
}

.container{
  max-width: 1085px;
}


.loading{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: rgba(255,255,255,0.9);
}
@media only screen and (max-width: 1096px) {
  .container{
    max-width: 98%;
  }
}

</style>
